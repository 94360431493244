import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color } = baseVariables;

export const StyledSingleSelectFlyout = styled.div`
  position: relative;
  min-width: ${toRem(200)};
  .icon-arrow-down,
  .icon-arrow-up {
    font-size: ${toRem(14)} !important;
    cursor: pointer;
  }
  .align-right {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    padding-bottom: ${toRem(9)};
    white-space: nowrap;
    button:focus-visible {
      outline: -webkit-focus-ring-color auto ${toRem(1)};
    }
  }
  label {
    font-weight: 700;
  }
  .m-custom-select-block button {
    button {
      white-space: normal;
      text-align: center;
      align-items: center;
      line-height: 1;
      span {
        height: auto;
      }
    }
  }
`;

export const StyledSingleSelectFlyoutOptions = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2;
  padding: ${toRem(16)} 0;
  background-color: ${color.base20};
  max-width: ${toRem(230)};
  border-radius: ${toRem(15)};
  border: ${toRem(1)} solid ${color.neutral30};
  .radio-controls-list__radio {
    padding-left: 0;
    padding-top: ${toRem(6)};
  }
`;
