import dynamic from 'next/dynamic';
import { EmailInfoProps } from './ReslinkEmailInfo.types';

export const ReslinkEmailInfoBau = dynamic<{ model: EmailInfoProps }>(() =>
  import('./ReslinkEmailInfo').then(mod => mod.ReslinkEmailInfo)
);

export const ReslinkEmailInfoDtt = dynamic<{ model: EmailInfoProps }>(() =>
  import('./ReslinkEmailInfoDtt').then(mod => mod.ReslinkEmailInfoDtt)
);

export const ReslinkEmailInfo = (isDtt: boolean) => (isDtt ? ReslinkEmailInfoDtt : ReslinkEmailInfoBau);
