import dynamic from 'next/dynamic';
import { EventSummaryListProps } from './EventSummaryList.types';

export const EventSummaryListBau = dynamic<{ model: EventSummaryListProps }>(() =>
  import('./EventSummaryList').then(mod => mod.EventSummaryList)
);

export const EventSummaryListDtt = dynamic<{ model: EventSummaryListProps }>(() =>
  import('./EventSummaryListDtt').then(mod => mod.EventSummaryListDtt)
);

export const EventSummaryList = (isDtt: boolean) => (isDtt ? EventSummaryListDtt : EventSummaryListBau);
