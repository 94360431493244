import dynamic from 'next/dynamic';
import { ReslinkEventDetailsProps } from './ReslinkEventDetails.types';

export const ReslinkEventDetailsBau = dynamic<{ model: ReslinkEventDetailsProps }>(() =>
  import('./ReslinkEventDetails').then(mod => mod.ReslinkEventDetails)
);

const ReslinkEventDetailsDtt = dynamic<{ model: ReslinkEventDetailsProps }>(() =>
  import('./ReslinkEventDetailsDtt').then(mod => mod.ReslinkEventDetailsDtt)
);

export const ReslinkEventDetails = (isDtt: boolean) => (isDtt ? ReslinkEventDetailsDtt : ReslinkEventDetailsBau);
