import dynamic from 'next/dynamic';
import { ConfirmEventSummaryProps } from './ConfirmEventSummary.types';

const ConfirmEventSummaryBau = dynamic<{ model: ConfirmEventSummaryProps }>(() =>
  import('./ConfirmEventSummary').then(mod => mod.ConfirmEventSummary)
);

const ConfirmEventSummaryDtt = dynamic<{ model: ConfirmEventSummaryProps }>(() =>
  import('./ConfirmEventSummaryDtt').then(mod => mod.ConfirmEventSummaryDtt)
);

export const ConfirmEventSummary = (isDtt: boolean) => (isDtt ? ConfirmEventSummaryDtt : ConfirmEventSummaryBau);
