import dynamic from 'next/dynamic';
import { ReslinkGuestProps } from './ReslinkGuest.types';

const ReslinkGuestBau = dynamic<{ model: ReslinkGuestProps }>(() => import('./ReslinkGuest').then(m => m.ReslinkGuest));

const ReslinkGuestDtt = dynamic<{ model: ReslinkGuestProps }>(() =>
  import('./ReslinkGuestDtt').then(m => m.ReslinkGuestDtt)
);

export const ReslinkGuest = (isDtt: boolean) => (isDtt ? ReslinkGuestDtt : ReslinkGuestBau);
