import { PropertyAddress } from '../../search/searchProperty.types';
import { PropertyBasicInfo } from '../getEventListPlanner.types';
import { ContactNumber } from '../getEventSummaryById.types';

export type GetEventSummaryByIdDttResponse = {
  hotelOperations: {
    groupManagement: {
      roomingListEventById: {
        title: string;
        property: Property;
        groupEvent: {
          groupBlock: {
            groupContract: GroupContract;
            products: Product[];
          };
          inventoryStatistics: InventoryStatistics;
        };
      };
    };
  };
};

type Property = {
  basicInformation: PropertyBasicInfo;
  contactInformation: {
    contactNumbers: ContactNumber[];
    address: PropertyAddress;
  };
};

export type GroupContract = {
  code: string;
  timespans: TimeSpan[];
  advanceBookingRestrictions: [
    {
      timespan: {
        end: string;
      };
    }
  ];
  derivedFields?: {
    isHousingProtected: boolean;
  };
  pricePlans: {
    priceGridDetails: {
      amountRules: AmountRule[];
    };
  }[];
};

type TimeSpan = {
  start: string;
  end: string;
};

export type AmountRule = {
  timespan?: TimeSpan;
  inventoryTypes: InventoryType[];
};

export enum ComputationType {
  FLAT = 'FLAT',
  AMOUNT_ADD = 'AMOUNT_ADD',
  AMOUNT_OFF = 'AMOUNT_OFF',
  PERCENT_ADD = 'PERCENT_ADD',
  PERCENT_OFF = 'PERCENT_OFF',
  BUY_AND_GET = 'BUY_AND_GET',
  FLEXIBLE = 'FLEXIBLE',
}

export type InventoryType = {
  code: string;
  ratePlanCode: string;
  rateModeCode: 'base-rate' | 'base-rate-double';
  computationTypeEnum: ComputationType;
  amountValue: {
    value: number;
    currencyCode: string;
    numberOfDecimals: number;
  };
};

export type Product = {
  inventoryTypeCode: string;
  roomTypeDetails: {
    name?: string;
    description: string;
    maxOccupancy?: number;
  };
};

export type InventoryStatistics = {
  groupByGroupPeriods: {
    period?: {
      start: string;
      end: string;
    };
    groups: {
      code: string;
      inventoryTypeCounters: {
        allocatedRoomsCounters: number[];
        bookingCounters?: number[];
      };
    }[];
  }[];
};
