import dynamic from 'next/dynamic';
import { PlannerNewEventProps } from './PlannerNewEvent.types';

export const PlannerNewEventBau = dynamic<{ model: PlannerNewEventProps }>(() =>
  import('./PlannerNewEvent').then(mod => mod.PlannerNewEvent)
);

const PlannerNewEventDtt = dynamic<{ model: PlannerNewEventProps }>(() =>
  import('./PlannerNewEventDtt').then(mod => mod.PlannerNewEventDtt)
);

export const PlannerNewEvent = (isDtt: boolean) => (isDtt ? PlannerNewEventDtt : PlannerNewEventBau);
