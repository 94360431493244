import React, { FC, useEffect, useState, useMemo } from 'react';
import { baseVariables } from '@marriott/mi-ui-library';
import { useMediaQuery } from '../../../hooks';
import { ViewToggle, MapView as Map, Drawer } from '../../../molecules';
import { useLocaleStore, useRfpTrayStore, useSearchResultsStore } from '../../../store';
import { MapViewProps } from './MapView.types';
import { StyledMapView } from './MapView.styles';
import { ListView } from '../ListView';
import { CardCarousel } from '../CardCarousel';
import { ViewType } from '../SearchResults.types';
import { RfpTrayView } from '../../RfpTray/RfpTray.types';
import { MAP_VIEW_HEIGHT_OFFSET, RFP_TRAY_HEIGHT } from '../../../constants';
import { StyledDrawerContent } from '../../../molecules/Drawer/Drawer.styles';

export const MapView: FC<MapViewProps> = ({ sortFilter, property, propertyDrawer, pagination, ctas, toggleView }) => {
  const { searchQuery, loader, properties, activePropertyId, setActivePropertyId } = useSearchResultsStore();
  const { visibility } = useRfpTrayStore();
  const { rtl } = useLocaleStore();

  const isDesktop = useMediaQuery(baseVariables.mediaQuery.lg);
  const isTablet = useMediaQuery(baseVariables.mediaQuery.md);

  const [showDrawer, setShowDrawer] = useState(true);

  useEffect(() => {
    // it will be refactored when the model json structure will change
    const el = document.querySelectorAll('.root');
    const footer = el[el.length - 1];
    footer?.setAttribute('style', 'display: none');
    return () => {
      footer?.setAttribute('style', 'display: block');
    };
  }, []);

  const headerHeight = document.querySelector('.m-header')?.getBoundingClientRect()?.height || 0;
  const searchFormHeight = document.querySelector('.search-results-form')?.getBoundingClientRect().height || 0;
  const filterBarHeight = document.querySelector('.filter-bar')?.getBoundingClientRect().height || 0;
  const rfpTrayHeight =
    visibility === RfpTrayView.COLLAPSED
      ? isTablet
        ? RFP_TRAY_HEIGHT.TABLET_AND_ABOVE_COLLAPSED
        : RFP_TRAY_HEIGHT.MOBILE_COLLAPSED
      : 0;
  const mapHeight =
    window.innerHeight - (headerHeight + searchFormHeight + filterBarHeight) - MAP_VIEW_HEIGHT_OFFSET - rfpTrayHeight;

  const center = useMemo(
    () => ({
      lat: searchQuery.search?.latitude || 0,
      lng: searchQuery.search?.longitude || 0,
    }),
    [searchQuery]
  );

  const propertyList = useMemo(() => properties.map(property => property.node), [properties]);

  return (
    <StyledMapView className="m-container-fullbleed">
      <div className="row align-items-stretch">
        {isDesktop ? (
          <div className={isDesktop && showDrawer ? 'card-panel' : ''}>
            <Drawer
              placement="left"
              initialState={showDrawer}
              variant={isDesktop ? 'drawer' : 'container'}
              labels={propertyDrawer}
              dir={rtl ? 'rtl' : 'ltr'}
              onChange={value => setShowDrawer(value)}
            >
              <StyledDrawerContent height={mapHeight} id="card-panel">
                {isDesktop && (
                  <ListView sortFilter={sortFilter} property={property} pagination={pagination} ctas={ctas} />
                )}
              </StyledDrawerContent>
            </Drawer>
          </div>
        ) : null}

        <div className={isDesktop && showDrawer ? 'map-view' : 'col-12 p-0'}>
          <Map
            center={center}
            height={mapHeight}
            propertyLabels={property}
            properties={propertyList}
            children={
              !isDesktop && activePropertyId ? (
                <CardCarousel ctas={ctas} propertyLabels={property} pagination={pagination} height={mapHeight} />
              ) : null
            }
            ctas={ctas}
            activePropertyId={activePropertyId}
            onPropertyClick={propertyId => setActivePropertyId(propertyId)}
          />
          <ViewToggle
            ctaLabel={ctas.list}
            variant={ViewType.LIST_VIEW}
            ctaAriaLabel={ctas.mapAriaLabel}
            trackLocation={ctas.listTrackLocation}
            toggleView={toggleView}
          />
          {loader ? (
            <div className="row map-view-loader">
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center loader">
                  <div className="m-spinner-m"></div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </StyledMapView>
  );
};
