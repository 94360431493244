import dynamic from 'next/dynamic';
import { AssociateLoginProps } from './AssociateLogin.types';

const AssociateLoginBau = dynamic<{ model: AssociateLoginProps }>(() =>
  import('./AssociateLogin').then(mod => mod.AssociateLogin)
);

const AssociateLoginDtt = dynamic<{ model: AssociateLoginProps }>(() =>
  import('./AssociateLoginDtt').then(mod => mod.AssociateLoginDtt)
);

export const AssociateLogin = (isDtt: boolean) => (isDtt ? AssociateLoginDtt : AssociateLoginBau);
