import dynamic from 'next/dynamic';
import { PlannerEventListProps } from './PlannerEventList.types';

const PlannerEventListBau = dynamic<{ model: PlannerEventListProps }>(() =>
  import('./PlannerEventList').then(mod => mod.PlannerEventList)
);

const PlannerEventListDtt = dynamic<{ model: PlannerEventListProps }>(() =>
  import('./PlannerEventListDtt').then(mod => mod.PlannerEventListDtt)
);

export const PlannerEventList = (isDtt: boolean) => (isDtt ? PlannerEventListDtt : PlannerEventListBau);
