import { PropertySearchResults } from '@marriott/mi-groups-graphql';
import { Property, Pagination, Ctas } from '../SearchResults.types';

export type CardCarouselSliderProps = Omit<CardCarouselProps, 'height'> & {
  properties: PropertySearchResults['edges'];
};

export type CardCarouselProps = {
  propertyLabels: Property;
  pagination: Pagination;
  ctas: Ctas;
  height: number;
};

export type PaginationSlideProps = {
  type: NavigationType;
  totalSlides: number;
  pagination: Pagination;
};

export enum NavigationType {
  PREVIOUS,
  NEXT,
}
