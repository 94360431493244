import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { baseVariables, Image } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import Glide, { Options } from '@glidejs/glide';
import { ImageCarouselProps, ImageProps } from './ImageCarousel.types';
import { StyledImageCarousel } from './ImageCarousel.styles';
import { getImageType, getPropertyImages } from '../../utils';
import { useMediaQuery } from '../../hooks';
import { ViewType } from '../../organisms/SearchResults/SearchResults.types';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';

const carouselGlideConfiguration = {
  type: 'slider',
  gap: 0,
  startAt: 0,
  focusAt: 'center',
  autoplay: false,
  bound: false,
  rewind: true,
  dragThreshold: false,
  swipeThreshold: false,
  perView: 1,
  keyboard: false,
  peek: {
    before: 0,
    after: 0,
  },
} as Options;

export const ImageCarousel: FC<ImageCarouselProps> = ({
  media,
  brandImage,
  labels,
  isCarouselIndicatorCentered = false,
  addScrimToImage,
  hasDescription = false,
  viewType = ViewType.QUICK_VIEW,
  containerClass = 'standalone-carousel',
  rewind = true,
  imageLoading = 'lazy',
  dir = 'ltr',
}) => {
  const { primaryImage, photoGallery } = media || {};
  const { carouselAriaLabel, imageAriaLabel } = labels || {};

  const isTablet = useMediaQuery(baseVariables.mediaQuery.md);
  const isDesktop = useMediaQuery(baseVariables.mediaQuery.lg);

  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const imageType = useMemo(() => getImageType(viewType, isDesktop, isTablet), [viewType, isDesktop, isTablet]);
  const images = useMemo(
    () => getPropertyImages(imageType, primaryImage, photoGallery, brandImage),
    [imageType, primaryImage, photoGallery, brandImage]
  );

  // to get the current image aria label
  const getImageAriaLabel = (altText: string) => {
    return `${carouselAriaLabel} ${imageAriaLabel
      ?.replace('{total}', `${images.length}`)
      .replace('{activeIndex}', `${+activeImageIndex + 1}`)} ${altText}`;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const glideRef = useRef<HTMLDivElement | any>(null);

  useEffect(() => {
    let glideInstance: Glide.Properties;
    const glideOnRun = () => {
      const index = glideRef?.current?.querySelector('.glide__slide--active')?.getAttribute('data-glide-index');
      setActiveImageIndex(index);
    };
    const onNextPrev = (event: KeyboardEvent) => {
      if (glideInstance) {
        if (event.key === 'ArrowLeft') {
          glideInstance.go('<');
        } else if (event.key === 'ArrowRight') {
          glideInstance.go('>');
        }
      }
    };

    if (images?.length > 1) {
      const glide = new Glide(glideRef?.current, { ...carouselGlideConfiguration, rewind, direction: dir });
      glide.on('run.after', glideOnRun);
      glideInstance = glide.mount();
      glideRef.current.addEventListener('keydown', onNextPrev);
    }

    return () => {
      if (glideRef?.current?.destroy) {
        glideRef.current.off('run.after', glideOnRun);
        glideRef.current.removeEventListener('keydown', onNextPrev);
        glideRef.current.destroy();
        glideRef.current = null;
      }
    };
  }, [images, rewind, dir]);

  return (
    <StyledImageCarousel
      data-component-name="m-groups-ImageCarousel"
      data-testid="groups-ImageCarousel"
      // data-testid="image-carousel"
    >
      <div className={clsx('carousel-container', containerClass)}>
        <div className="glide" ref={glideRef}>
          <div className="glide__track" data-glide-el="track" role="region">
            <ul className="glide__slides d-flex m-0 p-0" role="list" aria-label={carouselAriaLabel}>
              {images?.map((img: ImageProps, index: number) => (
                <li
                  className="glide__slide"
                  data-glide-index={index}
                  key={index}
                  role="listitem"
                  tabIndex={+activeImageIndex === index ? 0 : -1}
                  aria-label={getImageAriaLabel(img.altText)}
                  aria-live={+activeImageIndex === index ? 'polite' : 'off'}
                >
                  <Image
                    defaultImageURL={`${img.defaultImageUrl}`}
                    altText={`item${index + 1} ${img.altText}`}
                    customClass="carousel-image"
                    loading={
                      imageLoading === 'eager' || index === 0 || index === 1 || index === images.length - 1
                        ? 'eager'
                        : 'lazy'
                    }
                  />
                  {addScrimToImage && <div className="carousel-scrim-effect" />}
                </li>
              ))}
            </ul>

            {images.length > 1 ? (
              <>
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    type="button"
                    className={`glide__arrow ${
                      dir === 'ltr' ? 'glide__arrow--left left-arrow' : 'glide__arrow--right right-arrow'
                    }`}
                    data-glide-dir="<"
                    aria-label="Previous"
                  >
                    <span className={`icon-arrow-left`}></span>
                  </button>
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    type="button"
                    className={`glide__arrow ${
                      dir === 'ltr' ? 'glide__arrow--right right-arrow' : 'glide__arrow--left left-arrow'
                    }`}
                    data-glide-dir=">"
                    aria-label="Next"
                  >
                    <span className={`icon-arrow-right`}></span>
                  </button>
                </div>

                <div
                  data-glide-el="controls[nav]"
                  className={clsx(
                    'glide-bullet-container d-flex align-items-center px-2',
                    isCarouselIndicatorCentered && 'center'
                  )}
                >
                  {images?.map((_: ImageProps, index: number) => (
                    <div className="glide__bullet carousal-bullet p-0" data-glide-dir={`=${index}`} key={index}></div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      {hasDescription ? (
        <p className="mb-5" data-testid="image-description">
          {images[activeImageIndex].altText}
        </p>
      ) : null}
    </StyledImageCarousel>
  );
};
