import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';
import * as jwt from 'jsonwebtoken';

const getEIDFromToken = (token: string): string => {
  return (jwt.decode(token)?.sub as string) || '';
};

type AuthCookie = {
  RememberMeFlag?: string;
  UserIdToken?: string;
  sessionID: string;
};

type AuthState = {
  sessionId: string;
  userToken: string;
  eid: string;
  isAuthenticated?: boolean;
  isRemembered: boolean;
};

type AuthActions = {
  setAuthCookie: (cookie: AuthCookie) => void;
};

const initialState: AuthState = {
  sessionId: '',
  userToken: '',
  eid: '',
  isAuthenticated: false,
  isRemembered: false,
};

export const authStore: StateCreator<AuthState & AuthActions> = set => {
  return {
    ...initialState,

    setAuthCookie: (cookie: AuthCookie) => {
      set(prevState => {
        return {
          ...prevState,
          sessionId: cookie.sessionID || '',
          userToken: cookie.UserIdToken || '',
          eid: cookie.UserIdToken ? getEIDFromToken(cookie.UserIdToken) : '',
          isAuthenticated: cookie.UserIdToken ? true : false,
          isRemembered: !cookie.UserIdToken && cookie.RememberMeFlag ? true : false,
        };
      });
    },
  };
};

export const useAuthStore = createAppStore(authStore);
