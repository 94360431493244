import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { mediaQuery, spacers } = baseVariables;

export const StyledCardCarousel = styled.div<{ addToBottom: number; height: number }>`
  .slider {
    position: fixed;
    bottom: ${props => toRem(40 + props.addToBottom)};
    display: flex;
    justify-content: flex-end;
    & > div:first-child {
      margin-left: ${toRem(15)};
    }
    @media ${mediaQuery.md} {
      justify-content: center;
    }
    .property-card {
      max-width: none;
    }
    .carousel__inner-slide {
      padding-right: ${spacers.spacer};
    }
    .carousel__slide {
      left: ${toRem(2)};
      @media ${mediaQuery.md} {
        right: ${spacers.spacers?.[2]};
        left: ${spacers.spacer};
      }
    }
  }
  .carousel__slide {
    :dir(rtl) {
      transform: unset;
    }
  }
  .carousel__slider {
    :dir(rtl) {
      dirction: ltr;
      transform: unset;
    }
  }

  .pagination-slide-container {
    box-shadow: rgba(0, 0, 0, 0.07) 0 ${toRem(4)} ${toRem(20)} 0;
    border-radius: ${toRem(14)};
    max-width: ${toRem(360)};
    height: ${toRem(120)};
    .pagination-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: ${toRem(10)};
      margin: 0 auto;
    }
    @media ${mediaQuery.md} {
      max-width: 100%;
      height: ${toRem(240)};
      margin-top: ${spacers.spacer};
    }
  }

  .carouselControlType1 {
    .left-arrow,
    .right-arrow {
      position: static;
    }
  }
`;
