import { FC, useState, useMemo, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { CarouselProvider, Slide, Slider, CarouselContext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { baseVariables, useWindowSize } from '@marriott/mi-ui-library';
import { CardCarouselProps, PaginationSlideProps, NavigationType, CardCarouselSliderProps } from './CardCarousel.types';
import { StyledCardCarousel } from './CardCarousel.styles';
import { PropertyCard } from '../PropertyCard';
import { generatePaginationLabel } from '../../../utils';
import { useRfpTrayStore, useSearchResultsStore } from '../../../store';
import { useMediaQuery } from '../../../hooks';
import { RFP_TRAY_HEIGHT, SEARCH_RESULTS_PAGE_SIZE } from '../../../constants';
import { RfpTrayView } from '../../RfpTray/RfpTray.types';
import { GroupsRateProperty } from '@marriott/mi-groups-graphql';

const PaginationSlide = ({ type, totalSlides, pagination }: PaginationSlideProps) => {
  const isPrevious = type === NavigationType.PREVIOUS;

  const totalSearchResults = useSearchResultsStore(state => state.searchResults.total);
  const { currentPage, updatePage, setActivePropertyId } = useSearchResultsStore();

  const handlePageChange = (page: number) => {
    if (page !== currentPage) {
      updatePage((page - 1) * SEARCH_RESULTS_PAGE_SIZE);
      setActivePropertyId('');
    }
  };

  return (
    <Slide className="slide" index={isPrevious ? 0 : totalSlides + 1}>
      <div
        className={clsx('d-flex p-0 color-scheme2 pagination-slide-container')}
        data-testid={isPrevious ? 'previous-slide' : 'next-slide'}
      >
        <div
          className="pagination-slide"
          onClick={event => {
            if (event.cancelable) {
              event.preventDefault();
              event.stopPropagation();
              handlePageChange(isPrevious ? currentPage - 1 : currentPage + 1);
            }
          }}
        >
          {isPrevious ? pagination.previous : pagination.next}
          <div className="carouselControlType1">
            <button className={isPrevious ? 'left-arrow' : 'right-arrow'}>
              <span className={isPrevious ? 'icon-arrow-left' : 'icon-arrow-right'}></span>
            </button>
          </div>
          {generatePaginationLabel(
            pagination.paginationDetails,
            isPrevious ? currentPage - 1 : currentPage + 1,
            SEARCH_RESULTS_PAGE_SIZE,
            totalSearchResults
          )}
        </div>
      </div>
    </Slide>
  );
};

const CardCarouselSlider: FC<CardCarouselSliderProps> = ({ properties, propertyLabels, ctas, pagination }) => {
  const totalProperties = properties.length;

  const pageInfo = useSearchResultsStore(state => state.searchResults.pageInfo);
  const { setActivePropertyId, activePropertyId } = useSearchResultsStore();

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const carouselContext = useContext(CarouselContext);

  useEffect(() => {
    function onChange() {
      const currentSlide = carouselContext.state.currentSlide;
      const newPropertyId = properties[pageInfo?.hasPreviousPage ? currentSlide - 1 : currentSlide]?.node.id;
      if (newPropertyId && newPropertyId !== activePropertyId) {
        setActivePropertyId(newPropertyId);
      }
    }
    carouselContext?.subscribe(onChange);
    return () => carouselContext?.unsubscribe(onChange);
  }, [carouselContext, activePropertyId, setActivePropertyId, properties, pageInfo?.hasPreviousPage]);

  if (isTabletAndAbove === null) {
    return null;
  }

  return (
    <Slider classNameTray="slider">
      {pageInfo?.hasPreviousPage && (
        <PaginationSlide type={NavigationType.PREVIOUS} totalSlides={totalProperties} pagination={pagination} />
      )}
      {properties?.map((data, idx: number) => (
        <Slide className="slide" index={pageInfo?.hasPreviousPage ? idx + 1 : idx} key={data.node.id}>
          <PropertyCard
            variant={isTabletAndAbove ? 'regular' : 'mini'}
            propertyLabels={propertyLabels}
            ctas={ctas}
            propertyData={data.node}
            groupRate={(data as GroupsRateProperty)?.groupRate}
          />
        </Slide>
      ))}
      {pageInfo?.hasNextPage && (
        <PaginationSlide type={NavigationType.NEXT} totalSlides={totalProperties} pagination={pagination} />
      )}
    </Slider>
  );
};

export const CardCarousel: FC<CardCarouselProps> = ({ propertyLabels, ctas, pagination, height }) => {
  const { properties, activePropertyId } = useSearchResultsStore();
  const pageInfo = useSearchResultsStore(state => state.searchResults.pageInfo);
  const { visibility } = useRfpTrayStore();

  const totalProperties = properties.length;

  const isMobile = !useMediaQuery(baseVariables.mediaQuery.md);
  const { width } = useWindowSize();

  const activeSlideIndex = useMemo(
    () => properties.findIndex(data => data.node.id === activePropertyId),
    [activePropertyId, properties]
  );

  const [activeSlide, setActiveSlide] = useState<number>(activeSlideIndex);

  const rfpTrayHeight = useMemo(
    () =>
      visibility === RfpTrayView.COLLAPSED
        ? isMobile
          ? RFP_TRAY_HEIGHT.MOBILE_COLLAPSED
          : RFP_TRAY_HEIGHT.TABLET_AND_ABOVE_COLLAPSED
        : 0,
    [visibility, isMobile]
  );

  const totalSlides = useMemo(
    () =>
      pageInfo?.hasPreviousPage && pageInfo?.hasNextPage
        ? totalProperties + 2
        : pageInfo?.hasPreviousPage || pageInfo?.hasNextPage
          ? totalProperties + 1
          : totalProperties,
    [pageInfo, totalProperties]
  );

  useEffect(() => {
    activeSlideIndex > -1 && setActiveSlide(activeSlideIndex);
  }, [activeSlideIndex, properties]);

  return (
    <StyledCardCarousel addToBottom={rfpTrayHeight} height={height}>
      <CarouselProvider
        naturalSlideWidth={450}
        naturalSlideHeight={isMobile ? 200 : width && width > 650 ? 250 : 340}
        totalSlides={totalSlides + 0.08} // 0.08 added as visibleSlides is set to 1.08
        visibleSlides={1.08}
        isIntrinsicHeight={true}
        step={1}
        dragStep={1}
        currentSlide={pageInfo?.hasPreviousPage ? activeSlide + 1 : activeSlide}
      >
        <CardCarouselSlider
          properties={properties}
          propertyLabels={propertyLabels}
          ctas={ctas}
          pagination={pagination}
        />
      </CarouselProvider>
    </StyledCardCarousel>
  );
};
