import { FC, useEffect } from 'react';
import clsx from 'clsx';
import { useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import {
  PropertySearchResults,
  getPropertyMediaByIdsQuery,
  searchGroupRatesByGeolocationQuery,
  searchGroupRatesByDestinationQuery,
  searchGroupRatesByLocationQuery,
} from '@marriott/mi-groups-graphql';
import { InlineMessages, InlineMessagesType, baseVariables } from '@marriott/mi-ui-library';
import keyBy from 'lodash/keyBy';
import { PropertyQuickViewModal, ViewToggle } from '../../molecules';
import { PROPERTY_IMAGE_LIST_CATEGORY_LIMIT } from '../../constants';
import {
  getSearchQueryOptions,
  getQueryParams,
  getPropertyImages,
  getImageType,
  updateRouteWithSearchQuery,
  updateRouteWithView,
  isMapViewVisible,
  updateRouteWithDestination,
  updateDataLayerWithSearchAttributes,
  updateTrackCounter,
  updateViewType,
  updateAriesGroupSearchSession,
  isAllowedForLocale,
} from '../../utils';
import { useSearchFormStore, useSearchResultsStore, useRfpTrayStore, useLocaleStore } from '../../store/';
import { useMediaQuery } from '../../hooks';
import { FilterBar } from './FilterBar';
import { MapView } from './MapView';
import { ListView } from './ListView';
import { SearchResultsProps, SearchType, ViewType } from './SearchResults.types';
import { StyledSkeletonLoaderAtom } from './SkeletonLoader';
import {
  useSearchPropertiesByDestination,
  useSearchPropertiesByGeolocation,
  useSearchPropertiesByLocation,
} from './SearchResults.transformer';
import { CustomDomContentLoaded } from '../../components';

export const SearchResultsConfig = {
  emptyLabel: 'SearchResults',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/groupsearch/searchresults`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchResults: FC<{ model: SearchResultsProps }> = (props: any) => {
  const { property, propertiesPanel, viewHotelDetails, pagination, filters, ctas, error, styleclass } = props.model;
  const { addToRfp, addToRfpAriaLabel, selected, selectedAriaLabel, addToRfpTrackLocation } = ctas;
  const { sortFilter } = filters;

  const isDesktop = useMediaQuery(baseVariables.mediaQuery.lg);
  const isTablet = useMediaQuery(baseVariables.mediaQuery.md);

  const {
    loader,
    setLoader,
    searchType,
    searchQuery,
    updateSearchQuery,
    setSearchResults,
    properties,
    setPropertyMedia,
    setQueryFacets,
    showMapView,
    setShowMapView,
    setActivePropertyId,
    quickViewPropertyId,
    setQuickViewPropertyId,
    isQuickGroupEnabled,
    isModalOpen,
    setIsModalOpen,
    lastActiveElement,
  } = useSearchResultsStore();
  const { persistedFormData, setPersistedFormData } = useSearchFormStore();
  const { isPropertySelected, addProperty, removeProperty, resetOnDestinationUpdate } = useRfpTrayStore();
  const { locale, rtl } = useLocaleStore();

  const showMapViewToggle = isAllowedForLocale(locale) && !showMapView && properties?.length;
  const isHotelSelected = isPropertySelected(quickViewPropertyId);

  const [getSearchResultsByGeolocation, { error: searchPropertiesByGeolocationError }] =
    useSearchPropertiesByGeolocation();

  const [getSearchResultsByLocation, { error: searchPropertiesByLocationError }] = useSearchPropertiesByLocation();

  const [getSearchResultsByDestination, { error: searchPropertiesByDestinationError }] =
    useSearchPropertiesByDestination();

  const [getGroupsRatesByGeolocation, { error: searchGroupRatesByGeolocationError }] = useLazyQuery<{
    searchGroupRatesByGeolocation: PropertySearchResults;
  }>(searchGroupRatesByGeolocationQuery);

  const [getGroupsRatesByLocation, { error: searchGroupRatesByLocationError }] = useLazyQuery<{
    searchGroupRatesByLocation: PropertySearchResults;
  }>(searchGroupRatesByLocationQuery);

  const [getGroupsRatesByDestination, { error: searchGroupRatesByDestinationError }] = useLazyQuery<{
    searchGroupRatesByDestination: PropertySearchResults;
  }>(searchGroupRatesByDestinationQuery);

  const [getPropertyMediaByIds] = useLazyQuery(getPropertyMediaByIdsQuery);

  const setFocusToLastActiveElement = () => {
    if (lastActiveElement) {
      setTimeout(() => {
        lastActiveElement?.focus();
      }, 0);
    }
  };

  useEffect(() => {
    updateAriesGroupSearchSession();
  }, []);

  useEffect(() => {
    if (locale) {
      const queryOptions = getSearchQueryOptions(getQueryParams(window.location.search));
      isMapViewVisible() && setShowMapView();

      if (Object.keys(queryOptions).length) {
        updateSearchQuery(queryOptions, locale);
        searchQuery.search?.facets && setQueryFacets(searchQuery?.search?.facets);
        setPersistedFormData({
          ...persistedFormData,
          destination: {
            ...persistedFormData.destination,
            description: queryOptions?.destination || '',
          },
        });
        resetOnDestinationUpdate(queryOptions.destination || '');
      } else {
        setLoader(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    if (searchType === SearchType.NONE) return;

    const queryOptions = getSearchQueryOptions(getQueryParams(window.location.search));

    const uxlOptions: LazyQueryHookOptions = {
      variables: searchQuery,
      context: {
        headers: {
          'accept-language': locale,
          'uxl-mgp': 'true',
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        const searchResults =
          data?.search?.properties?.searchByGeolocation ||
          data?.search?.properties?.searchByLocation ||
          data?.search?.properties?.searchByDestination ||
          data?.searchGroupRatesByGeoLocation ||
          data?.searchGroupRatesByLocation ||
          data?.searchGroupRatesByDestination;

        if (searchResults) {
          setSearchResults(searchResults);
          const propertyIds = searchResults?.edges?.map((properties: { node: { id: string } }) => properties?.node.id);
          updateRouteWithSearchQuery(searchQuery, { view: showMapView ? 'map' : 'list' });
          updateDataLayerWithSearchAttributes(queryOptions, searchResults?.edges, isQuickGroupEnabled, '', locale);
          propertyIds.length && getPropertyMedia(propertyIds);
        }
        if (searchResults?.searchCenter) {
          setPersistedFormData({
            ...persistedFormData,
            destination: {
              ...persistedFormData.destination,
              description: searchResults?.searchCenter.address,
            },
          });
          updateRouteWithDestination(searchResults?.searchCenter.name);
        }

        setLoader(false);
        updateTrackCounter();
      },
      onError: error => {
        setLoader(false);
        updateDataLayerWithSearchAttributes(queryOptions, [], isQuickGroupEnabled, error.message, locale);
        updateTrackCounter();
      },
    };

    setLoader(true);

    if (searchType === SearchType.GEOLOCATION) {
      isQuickGroupEnabled ? getGroupsRatesByGeolocation(uxlOptions) : getSearchResultsByGeolocation(uxlOptions);
    } else if (searchType === SearchType.LOCATION_STATE || searchType === SearchType.LOCATION_COUNTRY) {
      isQuickGroupEnabled ? getGroupsRatesByLocation(uxlOptions) : getSearchResultsByLocation(uxlOptions);
    } else {
      isQuickGroupEnabled ? getGroupsRatesByDestination(uxlOptions) : getSearchResultsByDestination(uxlOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const getPropertyMedia = async (propertyIds: Array<string>) => {
    const uxlOptions: LazyQueryHookOptions = {
      variables: {
        ids: propertyIds,
        imageLimitPerCategory: PROPERTY_IMAGE_LIST_CATEGORY_LIMIT,
      },
      context: {
        headers: {
          'accept-language': locale,
        },
      },
      fetchPolicy: 'no-cache',
    };
    const { data, error } = await getPropertyMediaByIds(uxlOptions);
    if (error) {
      setPropertyMedia({});
      return;
    }
    const propertyMediaDetails = keyBy(data?.propertiesByIds, 'id');
    setPropertyMedia(propertyMediaDetails);
  };

  const toggleView = () => {
    setShowMapView();
    setActivePropertyId('');
    updateViewType(showMapView ? 'List' : 'Map');
    updateRouteWithView(!showMapView);
    updateTrackCounter();
  };

  const handlePropertySelection = () => {
    if (isHotelSelected) {
      removeProperty(quickViewPropertyId);
    } else {
      const quickViewProperty = properties.find(property => property.node.id === quickViewPropertyId);
      if (quickViewProperty) {
        const { basicInformation, media } = quickViewProperty.node;
        const imageType = getImageType(ViewType.LIST_VIEW, isDesktop, isTablet, false);
        const propertyImage = getPropertyImages(imageType, media?.primaryImage)[0];
        addProperty({ id: quickViewPropertyId, basicInformation, image: propertyImage });
      }
    }
  };

  return (
    <EditableComponent config={SearchResultsConfig} {...props}>
      <CustomDomContentLoaded>
        <div className={clsx(styleclass, styleclass?.includes('fullbleed') && 'm-container-fullbleed')}>
          <div className="container" data-testid="searchresults" data-component-name="o-groups-searchresults">
            {searchPropertiesByDestinationError ||
            searchPropertiesByLocationError ||
            searchPropertiesByGeolocationError ||
            searchGroupRatesByGeolocationError ||
            searchGroupRatesByLocationError ||
            searchGroupRatesByDestinationError ? (
              <div className="py-4">
                <InlineMessages type={InlineMessagesType.Error} title={error.apiFailure} severity="1" />
              </div>
            ) : !loader && !properties?.length ? (
              <div className="py-4">
                <InlineMessages type={InlineMessagesType.Error} title={error.noResults} severity="1" />
              </div>
            ) : (
              <>
                <div className="pt-4 filter-bar">
                  <FilterBar filters={{ ...filters, freeWifi: property.freeWifi }} filterModalLabels={ctas} />
                </div>

                <section>
                  {showMapView ? (
                    <MapView
                      toggleView={toggleView}
                      sortFilter={sortFilter}
                      property={property}
                      propertyDrawer={propertiesPanel}
                      pagination={pagination}
                      ctas={ctas}
                    />
                  ) : (
                    <ListView sortFilter={sortFilter} property={property} pagination={pagination} ctas={ctas} />
                  )}
                </section>

                {loader ? (
                  <StyledSkeletonLoaderAtom className="loader loader__page-info" height={40} />
                ) : (
                  showMapViewToggle && (
                    <ViewToggle
                      ctaLabel={ctas.map}
                      variant={ViewType.MAP_VIEW}
                      ctaAriaLabel={ctas.mapAriaLabel}
                      trackLocation={ctas.mapTrackLocation}
                      toggleView={toggleView}
                    />
                  )
                )}
              </>
            )}
          </div>
        </div>
        {isModalOpen ? (
          <PropertyQuickViewModal
            show={isModalOpen}
            propertyId={quickViewPropertyId}
            onAddToRfp={handlePropertySelection}
            labels={{
              ...viewHotelDetails,
              toAirport: property.toAirport,
              reviews: property.reviews,
              freeWifi: property.freeWifi,
              addToRfp: isHotelSelected ? selected : addToRfp,
              addToRfpAriaLabel: isHotelSelected ? selectedAriaLabel : addToRfpAriaLabel,
              addToRfpTrackLocation: addToRfpTrackLocation,
              mapAriaLabel: ctas.mapAriaLabel,
              reviewTooltipText: property.reviewTooltipText,
            }}
            error={error.apiFailure}
            footerClass={isHotelSelected ? 'selected' : ''}
            onCloseModal={() => {
              setIsModalOpen(false);
              setQuickViewPropertyId('');
              setFocusToLastActiveElement();
            }}
            dir={rtl ? 'rtl' : 'ltr'}
          />
        ) : null}
      </CustomDomContentLoaded>
    </EditableComponent>
  );
};
