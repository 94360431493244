import { EVENT_LIST_SIZE } from '../constants';
import {
  AvailabilityDetails,
  AvailabilityInfo,
  ComputationType,
  InventoryStatistics,
  InventoryType,
  Product,
  RateDetail,
} from '@marriott/mi-groups-graphql';
import { BookingStats } from '../organisms/rooming-list/PlannerNewEvent/EventBookingDetails/EventBookingDetails.types';
import { getBaseRate } from './reslink';

export const getClusterCode = (marshaCode: string) => (marshaCode.length === 5 ? '' : marshaCode.split('/')[0]);

export const getPropertyId = (marshaCode: string) => (marshaCode.length === 5 ? marshaCode : marshaCode.split('/')[1]);

export const getPageOffset = (pageNumber: number) => (pageNumber - 1) * EVENT_LIST_SIZE;

export const moveDecimalPoint = (number: number, positions: number) => {
  const updatedNumber = number / Math.pow(10, positions);
  return isNaN(updatedNumber) ? '-' : updatedNumber;
};

export const formatPhoneNumber = (phoneNumberWithExtension: string): string => {
  // Removing the extension
  let cleanedNumber = phoneNumberWithExtension.split(';')[0];

  // Find the index of the first space
  const firstSpaceIndex = cleanedNumber.indexOf(' ');

  // Replace subsequent spaces with hyphens
  if (firstSpaceIndex !== -1) {
    const prefix = cleanedNumber.slice(0, firstSpaceIndex + 1);
    const suffix = cleanedNumber.slice(firstSpaceIndex + 1).replace(/ /g, '-');
    cleanedNumber = prefix + suffix;
  }

  return cleanedNumber;
};

export const downloadTemplate = async (templateURL: string, defaultTemplateName: string) => {
  const url = window.location.origin + templateURL;
  const response = await fetch(url);
  const blob = await response.blob();
  const href = window.URL.createObjectURL(blob);

  const anchorElement = document.createElement('a');
  anchorElement.href = href;

  // Get the value of content-disposition header
  const contentDisposition = response.headers.get('content-disposition');

  // set the fileName variable to the default value
  let fileName = defaultTemplateName;

  // if the header is set, extract the filename
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    if (fileNameMatch?.length === 2) {
      fileName = fileNameMatch[1];
    }
  }

  anchorElement.download = fileName;

  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
};

export const getBookingStats = (availabilityInfo: AvailabilityInfo[]) => {
  const bookingStats: BookingStats = {
    availabilityList: [],
    totalSold: 0,
    totalRooms: 0,
    percentageOfRoomsSold: 0,
  };

  const filteredAvailabilityInfo = availabilityInfo
    .flatMap(item => item?.availabilitydetails)
    .filter(
      details => details?.authorized > 0 && new Date(details.startDate as string) < new Date(details.endDate as string)
    )
    .sort((a, b) => new Date(a?.startDate as string).getTime() - new Date(b?.startDate as string).getTime());

  if (filteredAvailabilityInfo.length) {
    const availabilityInfoByDate = filteredAvailabilityInfo.reduce(
      (acc: { [key: string]: AvailabilityDetails }, item) => {
        const startDate = new Date(item.startDate as string);
        const endDate = new Date(item.endDate as string);
        for (const currentDate = startDate; currentDate < endDate; currentDate.setDate(currentDate.getDate() + 1)) {
          const key = currentDate.toISOString().split('T')[0];
          acc[key] = acc[key] || { startDate: key, authorized: 0, sold: 0 };
          acc[key].authorized += item.authorized;
          acc[key].sold = (acc[key].sold || 0) + (item?.sold || 0);
          acc[key].percentageOfRoomsSold = calculatePercentage(acc[key].sold || 0, acc[key].authorized);
        }
        return acc;
      },
      {}
    );

    bookingStats.availabilityList = Object.values(availabilityInfoByDate);

    const { totalSold, totalRooms } = bookingStats.availabilityList.reduce(
      (acc, item) => {
        acc.totalSold += item?.sold || 0;
        acc.totalRooms += item.authorized;
        return acc;
      },
      { totalSold: 0, totalRooms: 0 }
    );

    bookingStats.totalSold = totalSold;
    bookingStats.totalRooms = totalRooms;
    bookingStats.percentageOfRoomsSold = calculatePercentage(totalSold, totalRooms);
  }

  return bookingStats;
};

const calculatePercentage = (value: number, total: number): number => Math.floor((value / total) * 100);

export const getGroupRates = (rates: RateDetail[], availabilityInfo: AvailabilityInfo[]) => {
  const filteredRates = rates.filter(rate => {
    const matchingAvailabilityDetails = availabilityInfo?.find(
      availabilityDetails => availabilityDetails.type === rate?.roomType
    )?.availabilitydetails;
    return matchingAvailabilityDetails?.some(item => item.authorized > 0);
  });

  return filteredRates?.sort((a, b) => +getBaseRate(a.rateAmounts)?.rateValue - +getBaseRate(b.rateAmounts)?.rateValue);
};

export const getRatesAndAvailability = (
  amountRules: { inventoryTypes: InventoryType[] }[],
  products: Product[],
  inventoryStatistics?: InventoryStatistics,
  groupCode?: string
) => {
  const rateDetails: RateDetail[] = [];
  const availabilityList: AvailabilityInfo[] = [];

  products.forEach(product => {
    const { inventoryTypeCode, roomTypeDetails } = product;

    const filteredAmountRules = amountRules.filter(amount =>
      amount.inventoryTypes.some(inventoryType => inventoryType.code === inventoryTypeCode)
    );

    const amounts = filteredAmountRules.flatMap(({ inventoryTypes }) => {
      return inventoryTypes.map(inventoryType => {
        return {
          ratePlanCode: inventoryType.ratePlanCode,
          rateMode: {
            code: (inventoryType.rateModeCode || inventoryType.computationTypeEnum === ComputationType.FLAT
              ? 'base-rate'
              : 'base-rate-double') as 'base-rate' | 'base-rate-double',
          },
          amount: {
            currency: inventoryType.amountValue.currencyCode,
            value: inventoryType.amountValue.value,
            valueDecimalPoint: inventoryType.amountValue.numberOfDecimals,
          },
        };
      });
    });

    const ratePlanCode = amounts[0]?.ratePlanCode;

    rateDetails.push({
      groupCode: ratePlanCode ? `${groupCode}${ratePlanCode}` : groupCode,
      roomType: inventoryTypeCode,
      description: inventoryTypeCode,
      roomTypeDetail: {
        description: roomTypeDetails?.description,
      },
      rateAmounts: amounts,
    });

    inventoryStatistics?.groupByGroupPeriods?.forEach(({ period, groups }) => {
      const filteredGroup = groups.find(group => group.code === inventoryTypeCode);

      availabilityList.push({
        type: filteredGroup?.code || '',
        availabilitydetails: [
          {
            startDate: period?.start,
            endDate: period?.end,
            authorized: filteredGroup?.inventoryTypeCounters?.allocatedRoomsCounters?.[0] || 0,
            sold: filteredGroup?.inventoryTypeCounters?.bookingCounters?.[0] || 0,
          },
        ],
      });
    });
  });

  return { rateDetails, availabilityList };
};
