import { CalendarOptions } from '../../molecules/DatePicker/DatePicker.types';
import { SignInModalLabels } from '../../molecules/SignInModal/SignInModal.types';
import { BookingSummary } from './BookingSummaryModal/BookingSummaryModal.types';

export type StepLabels = {
  guestRooms: string;
  eventSpaces: string;
  review: string;
  billingAndContact: string;
};

export type QuickGroupHeaderProps = StepLabels &
  CalendarOptions & {
    pageTitle: string;
    submitAnInquiry: string;
    eventSpacesHelperText: string;
    submitAnInquiryHelperText: string;
    currentPageNo: number;
    currentStep: string;
    viewBookingSummary: string;
    bookingSummary: BookingSummary;
    signInLabel: string;
    signInModal: SignInModalLabels;
    styleclass: string;
  };

export enum QuickGroupPageIdentifier {
  GUEST_ROOMS = 'guest-rooms',
  EVENT_SPACE = 'event-space',
  REVIEW_DETAILS = 'review-details',
  BILLING_AND_CONTACT = 'billing-contact',
  SUBMIT_INQUIRY = 'submit-inquiry',
}
